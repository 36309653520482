import { auth, theme } from "@project/shared"
import type { MenuProps } from "antd"
import { Drawer, Grid, Menu } from "antd"
import { signOut } from "firebase/auth"
import Router, { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { useGlobalContext } from "../../../context/GlobalContextProvider"
import HamburgerMenuIcon from "../../../public/assets/icons/bars_hoso 1.svg"
import { getPendingApprovalCount } from "../../../services/family"
import { authContext } from "../../../utils"
import HeaderButton from "../../atoms/HeaderButton/HeaderButton"
import LockModal from "./LockModal"
import {
  dataFirstNav,
  dataFourthNav,
  dataSecondNav,
  dataThirdNav,
} from "./MenuItems"

import { CloseOutlined, LeftOutlined } from "@ant-design/icons"
import {
  addUserDetail,
  lockContent,
  openLockedModal,
} from "../../../context/Action"
import { getSingleUserDetails } from "../../../services/user"
import { checkAccessibilityCondition } from "../../../utils/helpers/accessibility/checkLockedItem"
import CommonMobileMenu from "../CommonMobileMenu"

interface PageHeaderProps {
  readonly?: boolean
  heading?: string
}

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 900;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem 0 1.25rem;
  background: ${"#F8F3E9"};
  width: 100%;
  border-bottom: solid #e7e7e7;
  height: 6.875rem;
  @media only screen and (max-width: 765px) {
    height: 55px;
    display: flex;
    align-items: center;
    padding: 0 1.25rem 0 1.25rem;
    justify-content: space-between;
  }

  .backBtn {
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px 8px;
    background-color: white;

    position: absolute;

    .text {
      margin-left: 5px;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    svg {
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
`
const UserNameBox = styled.div`
  background: ${theme.background.secondary};
  border-radius: 100px;
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.07);
  display: flex;
  margin: 0.313rem 0 auto 0.313rem;
  margin-right: 30px;
  height: 3.75rem;
  width: 21.25rem;
  justify-content: space-between;
  padding: 1.25rem;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    width: 15.25rem;
  }
`
const MobileUserNameBox = styled.div`
  background: ${theme.background.secondary};
  border-radius: 100px;
  border: 1px solid #dddcdc;
  display: flex;
  margin-bottom: 1.25rem;
  width: 20.938rem !important;
  height: 2.813rem;
  font-size: 16px;
  font-family: ${theme.font.BUS};
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 0 1.188rem 0 1.188rem;
  @media only screen and (max-width: 380px) {
    width: 100% !important;
  }
`
const NavigationButtonBox = styled.div`
  display: flex;
  flex-direction: row;
`
const PageHeaderBox = styled.div`
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.regular};
  font-size: 18px;
  position: absolute;
  left: 53%;
  transform: translateX(-50%);
  width: 40%;
  text-align: center;
  word-break: keep-all;
  @media (max-width: 495px) {
    white-space: break-spaces;
  }
  @media (max-width: 330px) {
    font-size: 1rem;
  }
`

const MenuWrapper = styled.div`
  ant-menu {
    width: 300px;
  }
  .ant-menu-item-selected {
    background: white !important;
    color: #4696c4 !important;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
`
const DrawerWrapper = styled(Drawer)`
  margin-top: 55px;
  height: calc(100% - 55px);
  overflow-y: scroll;
  .ant-drawer-body {
    padding: 20px !important;
    @media (max-width: 380px) {
      padding: 8px !important;
    }
  }
  .ant-menu {
    border-right: none !important;
    background: white !important;
  }
  .ant-menu-item-selected {
    color: #1890ff !important;
    background: white !important;
    ::after {
      content: "";
      border-right: none !important ;
    }
  }
  .ant-menu-item-icon {
    margin-right: 1.313rem !important ;
  }
  .ant-menu-submenu-inline {
    border-top: 1px solid #dddcdc !important;
    :last-child {
      border-bottom: 1px solid #dddcdc !important;
    }
  }
  .ant-menu-inine {
    padding-bottom: 0 !important ;
  }
  .ant-menu-submenu-title {
    height: 70px !important;
  }
  .ant-menu-submenu-selected {
    color: ${theme.text.color.dark};
  }
  .ant-menu-title-content {
    font-family: ${theme.font.BUS};
    margin-left: 0 !important;
    @media (max-width: 380px) {
      font-size: 18px !important;
      font-weight: 500;
    }
    @media (max-width: 750px) {
      font-size: 18px !important;
      font-weight: 500;
    }
  }
  .ant-menu-submenu-arrow {
    color: ${theme.text.color.dark} !important;
  }
  .ant-menu-item {
    padding-left: 1.563rem !important;
    font-family: ${theme.font.BUS};
    height: 3.75rem !important;
    border-top: 1px solid #dddcdc !important;
    font-size: 18px;
    font-weight: 500px;
  }
  @media only screen and (max-width: 380px) {
    .ant-drawer-content-wrapper {
      width: 320px !important;
    }
  }
`

const MenuBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 1.25rem;

  .anticon svg {
    font-size: 20px;
  }
`

const MenuTextWrapper = styled.div`
  font-family: ${theme.font.BUS};
  font-weight: 500;
  font-size: 10px;
  font-size: 16px;
`

const MenuCloseWrapper = styled.div`
  font-family: ${theme.font.NSJP};
  font-weight: 500;
  font-size: 10px;
  font-size: 16px;
  margin-top: 5px;
`

const PageHeader: React.FC<PageHeaderProps> = () => {
  const { pathname } = useRouter()
  const router = useRouter()
  const pathnameParts = pathname.split("/").filter(Boolean)
  const expectedResults = pathnameParts.slice(0, 1).join("/")

  const PAGE_TITLES = {
    "my-thoughts": "My thoughts",
    top: "Home",
    "friends-property": "Friends・Property list",
    "pictures-memories": "Share important pictures and memories",
    family: "Family list",
    "invite-family": "Invite family",
    "approve-family": "Approvals",
    "careful-notes": "シェアする前に\n お読みください", //here jp translated value is used for line break
    inquiry: "Important inquiry",
    "delete-account": "Delete account",
    "update-information": "Update information",
    "family-information": "Family information",
  }
  const isPathForHome = router.pathname == "/top" ? true : false
  const isPathForNotes =
    router.pathname == "/friends-property" ||
    router.pathname == "/my-thoughts" ||
    router.pathname == "/pictures-memories"
      ? true
      : false
  const isPathForFamily =
    router.pathname == "/family" ||
    router.pathname == "/invite-family" ||
    router.pathname == "/approve-family" ||
    router.pathname == "/careful-notes"
      ? true
      : false
  const isPathForInquiry =
    router.pathname == "/inquiry" || router.pathname == "/delete-account"
      ? true
      : false
  const isPathForSettings =
    router.pathname == "/update-information" ? true : false

  const { data: pendingApprovalCount } = useQuery(
    "pending-approval-count",
    getPendingApprovalCount
  )

  /**
   * Access global state
   */

  const { state, dispatch } = useGlobalContext()

  /**
   * Accessing Auth context and handling log out.
   */

  const [userName, setUserName] = useState({ userData: "" })
  const { user, setUser, userId, setUserId } = authContext()

  useEffect(() => {
    if (user) {
      ;(async () => {
        const idToken = await user!.getIdTokenResult()
        setUserId(idToken.claims["id"])
      })()
    }
  }, [user])

  const {
    data: userDetailData,
    refetch,
    isLoading: isUserDataFetching,
  } = useQuery(
    ["user-detail", userId],
    () => userId && getSingleUserDetails(userId),
    {
      select: (response) => {
        return response?.data
      },
    }
  )

  useEffect(() => {
    userDetailData &&
      dispatch(
        addUserDetail({ ...userDetailData?.accessibility, isLoading: false })
      )

    setUserName({
      userData: userDetailData?.name,
    })
  }, [userDetailData])

  const { t } = useTranslation()

  const handleLogOut = (): void => {
    signOut(auth)
    setUser(null)
  }

  /**
   * @isOpenDrawer drawer state
   */
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)

  /**
   * @params @returns void.
   * Menu button handler
   */

  const mobileMenuHandler = () => {
    setIsOpenDrawer(true)
  }

  /**
   *
   * Ant design hooks for accessing the breakpoints.
   */
  const { useBreakpoint } = Grid

  const screenSize = useBreakpoint()

  const handleLockIconClick = (item: string) => {
    dispatch(openLockedModal())
    dispatch(lockContent(item))
  }

  const lockedItemsArray: Array<boolean> = checkAccessibilityCondition(
    state?.userAccessibilityData
  )

  /**
   *
   * @params t
   * @returns menu items.
   */

  const data1: MenuProps["items"] = dataFirstNav(
    t,
    handleLockIconClick,
    lockedItemsArray[0],
    lockedItemsArray[1],
    lockedItemsArray[2],
    isUserDataFetching
  )
  const data2: MenuProps["items"] = dataSecondNav(
    t,
    pendingApprovalCount?.count
  )
  const data3: MenuProps["items"] = dataThirdNav(t)
  const data4: MenuProps["items"] = dataFourthNav(t, handleLogOut)

  /**
   * handlers for showing active state of menu.
   * @currentPath looks actual path (beside base path)
   * @activeKey shows the current link active eg,family, approve-family etc.
   * @openedKeys state is for opening the parent menu items(only for the mobile menu items)
   */
  const [activeKey, setActiveKey] = useState<string>("")

  /**
   *
   * Menu requierd for the Navigation.
   */
  const menuItemOne = (
    <MenuWrapper>
      <Menu selectedKeys={[activeKey]} items={data1} />
    </MenuWrapper>
  )
  const menuItemTwo = (
    <MenuWrapper>
      <Menu selectedKeys={[activeKey]} items={data2} />
    </MenuWrapper>
  )
  const menuItemThree = (
    <MenuWrapper>
      <Menu selectedKeys={[activeKey]} items={data3} />
    </MenuWrapper>
  )
  const menuItemFour = (
    <MenuWrapper>
      <Menu selectedKeys={[activeKey]} items={data4} />
    </MenuWrapper>
  )

  return (
    <>
      <LockModal refetch={refetch} />
      <DrawerWrapper
        placement="right"
        onClose={() => setIsOpenDrawer(false)}
        visible={isOpenDrawer}
        closable={false}
        maskClosable
        destroyOnClose
        mask
        getContainer={false}
      >
        <MobileUserNameBox>
          <div>{userName?.userData ?? "お客様名"}</div>
          <div>様</div>
        </MobileUserNameBox>
        <CommonMobileMenu
          handleLogOut={handleLogOut}
          pendingApprovalCount={pendingApprovalCount}
          handleLockIconClick={handleLockIconClick}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          lockedItemsArray={lockedItemsArray}
        />
      </DrawerWrapper>
      <HeaderWrapper>
        {(screenSize.xs || !screenSize.md) && !screenSize.xl && (
          <>
            {router.pathname !== "/top" && (
              <button
                className="backBtn"
                onClick={() => {
                  const getFilterFromLocal = JSON.parse(
                    localStorage?.getItem("filterMemo")
                  )
                  if (getFilterFromLocal === "user") {
                    Router.push("/top")
                  } else {
                    Router.back()
                  }
                }}
              >
                <LeftOutlined />
                <span className="text">{t("Back")}</span>
              </button>
            )}

            <PageHeaderBox>
              {isOpenDrawer ? t("Menu") : t(PAGE_TITLES[expectedResults])}
            </PageHeaderBox>

            {!isOpenDrawer ? (
              <MenuBox>
                <HamburgerMenuIcon
                  onClick={mobileMenuHandler}
                  showIcon={isOpenDrawer}
                  style={{ cursor: "pointer" }}
                />

                <MenuTextWrapper>{t("Menu")}</MenuTextWrapper>
              </MenuBox>
            ) : (
              <MenuBox>
                <CloseOutlined
                  style={{
                    cursor: "pointer",
                    color: "#6b6868",
                  }}
                  onClick={() => setIsOpenDrawer(false)}
                />
                <MenuCloseWrapper onClick={() => setIsOpenDrawer(false)}>
                  {t("Close")}
                </MenuCloseWrapper>
              </MenuBox>
            )}
          </>
        )}

        {!screenSize.xs && (screenSize.xl || screenSize.md) && (
          <>
            <UserNameBox>
              <div>{userName?.userData ?? "お客様名"}</div>
              <div>様</div>
            </UserNameBox>

            <NavigationButtonBox>
              <div className="home-wrapper" onClick={() => router.push("/top")}>
                <HeaderButton
                  isActive={isPathForHome}
                  icon={
                    <img
                      src="/assets/icons/home.svg"
                      width="31px"
                      height="32px"
                    />
                  }
                  disable
                  heading={t("Home")}
                />
              </div>
              <HeaderButton
                isActive={isPathForNotes}
                icon={
                  <img
                    src="/assets/icons/note-edit.svg"
                    width="31px"
                    height="32px"
                  />
                }
                items={menuItemOne}
                heading={t("Write notes")}
              />
              <HeaderButton
                isActive={isPathForFamily}
                icon={
                  <img
                    src="/assets/icons/user.svg"
                    width="31px"
                    height="32px"
                  />
                }
                heading={t("Family share")}
                items={menuItemTwo}
                badge={pendingApprovalCount?.count}
              />
              <HeaderButton
                isActive={isPathForInquiry}
                icon={
                  <img
                    src="/assets/icons/mail_user.svg"
                    width="31px"
                    height="32px"
                  />
                }
                heading={t("Inquiry")}
                items={menuItemThree}
              />
              <HeaderButton
                isActive={isPathForSettings}
                icon={
                  <img
                    src="/assets/icons/settings.svg"
                    width="31px"
                    height="32px"
                  />
                }
                heading={t("Settings")}
                items={menuItemFour}
              />
            </NavigationButtonBox>
          </>
        )}
      </HeaderWrapper>
    </>
  )
}

export { PageHeader }
