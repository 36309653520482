import { PlayCircleOutlined, RightOutlined } from "@ant-design/icons"
import { auth, theme } from "@project/shared"
import { MenuProps, Spin } from "antd"
import { signOut } from "firebase/auth"
import Link from "next/link"
import Router from "next/router"
import styled from "styled-components"
import { LOCKITEMS_TYPES } from "../../../constants/lockItems"
import { SwitchIcon } from "../../atoms"

const TagWrapper = styled.span`
  border-radius: 100px;
  margin-left: 10px;
  color: white;
  border: none;
  font-size: 16px;
  padding: 5px 14px;
  font-family: ${theme.font.BUS} !important;
  background: ${theme.button.primary};
`
const LabelWrapper = styled(Link)`
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.regular};
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
  @media (min-width: 380px) and (max-width: 750px) {
    font-size: 18px !important;
  }
`

const LabelWrapper2 = styled.span`
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.regular};
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
  @media (min-width: 380px) and (max-width: 750px) {
    font-size: 18px !important;
  }
`

// const IconWrapper = styled.span`
//   position: relative;
//   top: 3px;
//   left: 30px;
//   @media only screen and (max-width: 765px) {
//     left: 30px;
//   }
// `
// const MenuLockWrapper = styled.span`
//   position: relative;
//   top: 3px;

//   .home__icon path {
//     stroke: red;
//     transform: scale(1.5);
//   }

//   .home__icon_unlock path {
//     transform: scale(1.5);
//   }
// `
const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  .item-contain {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    width: 80%;
  }
  @media only screen and (max-width: 765px) {
    gap: 5px;
  }
`

/**
 *
 * @keys prefix are declared according to the mobile view items.
 *  i.e 1.home, 2.notes, 3.family, 4.inquiry and 5.settings
 */
export const dataFirstNav = (
  t: any,
  handleLockIconClick?: (item: string) => void,
  isLockedFirst?: boolean,
  isLockedSecond?: boolean,
  isLockedThird?: boolean,
  isLoading?: boolean,
  flexDir?: "row" | "row-reverse" | "column"
): MenuProps["items"] => [
  {
    key: "21",
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          <div onClick={() => localStorage.removeItem("filterMemo")}>
            <LabelWrapper href="/my-thoughts">{t("My thoughts")}</LabelWrapper>
          </div>
        </div>
        <div>
          {!isLoading ? (
            <SwitchIcon
              status={isLockedSecond ? "private" : "public"}
              onLockClick={() =>
                handleLockIconClick(LOCKITEMS_TYPES?.my_thoughts)
              }
              style={{
                flexDirection: flexDir ? flexDir : "row-reverse",
                display: "flex",
                gap: "4px",
                marginTop: "4px",
                alignItems: "flex-end",
              }}
            />
          ) : (
            <Spin />
          )}
        </div>
      </MenuWrapper>
    ),
  },
  {
    key: "20",
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          <div onClick={() => localStorage.removeItem("filterMemo")}>
            <LabelWrapper href="/friends-property">
              {t("Friends・Property list")}
            </LabelWrapper>
          </div>
        </div>
        <div>
          {!isLoading ? (
            <SwitchIcon
              status={isLockedFirst ? "private" : "public"}
              onLockClick={() =>
                handleLockIconClick(LOCKITEMS_TYPES?.friends_properties)
              }
              style={{
                flexDirection: flexDir ? flexDir : "row-reverse",
                display: "flex",
                gap: "4px",
                marginTop: "4px",
                alignItems: "flex-end",
              }}
            />
          ) : (
            <Spin />
          )}
        </div>
      </MenuWrapper>
    ),
  },
  {
    key: "22",
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          <LabelWrapper href="/pictures-memories">
            {t("Share important pictures and memories")}
          </LabelWrapper>
        </div>

        <div>
          {!isLoading ? (
            <SwitchIcon
              status={isLockedThird ? "private" : "public"}
              onLockClick={() => handleLockIconClick(LOCKITEMS_TYPES?.medias)}
              style={{
                flexDirection: flexDir ? flexDir : "row-reverse",
                display: "flex",
                gap: "4px",
                marginTop: "4px",
                alignItems: "flex-end",
              }}
            />
          ) : (
            <Spin />
          )}
        </div>
      </MenuWrapper>
    ),
  },
]

export const dataSecondNav = (
  t: any,
  unApprovedNumber?: number
): MenuProps["items"] => [
  {
    key: "31",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Family invitation")}
        </div>
      </MenuWrapper>
    ),
    onClick: () =>
      Router.pathname === "/invite-family"
        ? location.reload()
        : Router.push("/invite-family"),
  },
  {
    key: "32",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Approvals")}
          <TagWrapper>
            <span>{unApprovedNumber}件</span>
          </TagWrapper>
        </div>
      </MenuWrapper>
    ),
    onClick: () => Router.push("/approve-family"),
  },
  {
    key: "30",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Family list/Invited list")}
        </div>
      </MenuWrapper>
    ),
    onClick: () => Router.push("/family"),
  },
  {
    key: "33",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Careful notes")}
        </div>
      </MenuWrapper>
    ),
    onClick: () => Router.push("/careful-notes"),
  },
]

export const dataThirdNav = (t: any): MenuProps["items"] => [
  {
    key: "40",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Important inquiry")}
        </div>
      </MenuWrapper>
    ),
    onClick: () =>
      Router.pathname === "/inquiry"
        ? location.reload()
        : Router.push("/inquiry"),
  },
  {
    key: "42",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Delete your account")}
        </div>
      </MenuWrapper>
    ),
    onClick: () => Router.push("/delete-account"),
  },
]

export const consultantMemoMenu = (
  t: any,
  handleLockIconClick?: (item: string) => void,
  isConsultantMemoLocked?: boolean,
  isLoading?: boolean
): MenuProps["items"] => [
  {
    key: "51",
    // icon: <PlayCircleOutlined className="play-video-icon" />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <PlayCircleOutlined className="play-video-icon" />
          {t("相談メモの使い方")}
        </div>
      </MenuWrapper>
    ),
    onClick: () =>
      window.open("https://www.youtube.com/watch?v=mwRG0hPPLmg", "_blank"),
  },
  {
    key: "38",
    label: (
      <>
        <MenuWrapper>
          <div className="item-contain">
            <RightOutlined />
            <div
              onClick={() => {
                const currentRoute = `${Router.pathname}`
                if (currentRoute === "/consultation-memo/admin") {
                  // Reload the page
                  location.reload()
                } else {
                  Router.push("/consultation-memo/admin")
                }
              }}
            >
              <LabelWrapper2>{t("Consult record list")}</LabelWrapper2>
            </div>
          </div>

          <div>
            {!isLoading ? (
              <SwitchIcon
                status={isConsultantMemoLocked ? "private" : "public"}
                onLockClick={() =>
                  handleLockIconClick(LOCKITEMS_TYPES?.consultations)
                }
                style={{
                  flexDirection: "column",
                  display: "flex",
                  gap: "4px",
                  marginTop: "4px",
                  alignItems: "flex-end",
                }}
              />
            ) : (
              <Spin />
            )}
          </div>
        </MenuWrapper>
      </>
    ),
  },
  {
    key: "39",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("My memo list")}
        </div>
      </MenuWrapper>
    ),
    onClick: () => {
      const currentRoute = `${Router.pathname}`

      if (currentRoute === "/consultation-memo/user") {
        // Reload the page
        location.reload()
      } else {
        Router.push("/consultation-memo/user")
      }
    },
  },
  {
    key: "37",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Consultation Memo・ Add")}
        </div>
      </MenuWrapper>
    ),
    onClick: () => {
      Router.pathname === "/consultation-memo/add"
        ? location.reload()
        : Router.push("/consultation-memo/add")
    },
  },
]

export const dataFourthNav = (
  t: any,
  handleLogout = (): void => {
    signOut(auth)
  }
): MenuProps["items"] => [
  {
    key: "50",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Update information")}
        </div>
      </MenuWrapper>
    ),
    onClick: () => Router.push("/update-information"),
  },
  {
    key: "51",
    // icon: <RightOutlined />,
    label: (
      <MenuWrapper>
        <div className="item-contain">
          <RightOutlined />
          {t("Logout")}
        </div>
      </MenuWrapper>
    ),
    onClick: handleLogout,
  },
]
