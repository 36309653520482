import { CloseOutlined } from "@ant-design/icons"
import { theme } from "@project/shared"
import { Modal, ModalProps } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
const ModalBodyContent = styled.div`
  height: auto;
  /* max-height: 644px; */
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .icon_container {
    padding: 10px 10px 0px 0px;
    position: absolute;
    right: 0px;
    .close-icon {
      background-color: ${theme.background.secondary};
      border-radius: 50%;
      cursor: pointer;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
      svg {
        color: black;
        font-size: 20px;
        font-weight: bold;
      }
    }
    @media screen and (max-width: 250px) {
      padding: 5px 5px 0px 0px;
    }
  }
`
export const GuideModal: React.FC<ModalProps> = ({ ...props }) => {
  const [width, setWidth] = useState(window?.innerWidth)
  useEffect(() => {
    if (window) {
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
    }
    return () => {
      if (window) {
        window.removeEventListener("resize", () => {
          setWidth(window.innerWidth)
        })
      }
    }
  }, [])

  return (
    <Modal
      {...props}
      footer={null}
      closeIcon={<div />}
      width={780}
      style={{
        borderRadius: "12px",
        overflow: "hidden",
      }}
      bodyStyle={{
        padding: "0px",
        background: "rgba(248, 245, 239, 1)",
      }}
      wrapClassName="custom-modal"
    >
      <ModalBodyContent>
        <div className="icon_container">
          <div className="close-icon" onClick={props.onCancel}>
            <CloseOutlined />
          </div>
        </div>
        <img
          src={
            width && width < 500
              ? "/assets/imgs/guide-small.png"
              : "/assets/imgs/guide.png"
          }
          alt=""
        />
      </ModalBodyContent>
    </Modal>
  )
}
