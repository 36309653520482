import { Button, theme } from "@project/shared"
import Router from "next/router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { GuideModal } from "./GuideModal"

const CallToActionBtnWrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: ${(props) => props.theme.background.primary};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .cta-content {
    min-width: 512px;
    padding: 0 20px;
    .cta-content-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .information-text {
        margin-top: 8px;
        text-decoration: underline;
        font-weight: 500;
        font-size: 16px;
        line-height: 19.2px;
        cursor: pointer;
      }
      .login-text {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 8px;
        span {
          text-decoration: underline;
          font-weight: 500;
          font-size: 18px;
          line-height: 21.6px;
        }
      }
    }
    @media screen and (max-width: 512px) {
      min-width: 100%;
      padding: 0 10px;
      .cta-content-top {
        button {
          span {
            font-size: 14px;
          }
        }
        .information-text {
          font-size: 14px;
        }
        .login-text {
          span {
            font-size: 14px;
          }
        }
      }
    }
    @media screen and (max-width: 280px) {
      min-width: 100%;
      padding: 0 10px;
      .cta-content-top {
        button {
          span {
            font-size: 12px;
          }
        }
        .information-text {
          font-size: 12px;
        }
        .login-text {
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 512px) {
    justify-content: flex-start;
    padding-top: 50px;
  }
`
const BtnText = styled.span`
  font-size: 22px;
  line-height: 26.4px;
  font-weight: 700;
  @media screen and (max-width: 650px) {
    font-size: 17px;
    line-height: 20.4px;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-top: 25px;
  .btn-how-to-use,
  .btn-experience {
    display: flex;
    height: 59px;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 8.5px;
    border-radius: 10px;
    border: 1px solid #e26e30;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    .img_container {
      height: 39px;
      width: 39px;
      border-radius: 50%;
      background: rgba(247, 245, 240, 1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text {
      color: #e26e30 !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21.7px;
    }
    @media screen and (max-width: 512px) {
      gap: 4px;
      .text {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 512px) {
    gap: 8px;
  }
  @media screen and (max-width: 280px) {
    flex-direction: column;
    .btn-how-to-use,
    .btn-experience {
      width: 100%;
    }
  }
`
export const CallToActionBtn = () => {
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation()
  const redirectToLink = () => {
    window.open(
      "https://www.moshimo.net/line/kaki-oki/index.html?utm_source=social&utm_medium=line&utm_campaign=kak-oki_taikendan",
      "_blank"
    )
  }
  return (
    <>
      <CallToActionBtnWrapper>
        <div className="cta-content">
          <div className="cta-content-top">
            <Button
              background={theme.background.navBar.secondary}
              className="btn"
              onClick={() => Router.push("/login")}
              height={"70px"}
              block
            >
              <BtnText>エンディングノートをご利用の方はこちら</BtnText>
            </Button>
            <Button
              background={"#65A343"}
              className="btn"
              onClick={() => Router.push("/login")}
              height={"70px"}
              block
            >
              <BtnText>事前相談された方はこちら</BtnText>
            </Button>

            <div
              className="information-text"
              onClick={() => setOpenModal(true)}
            >
              ※事前相談された方のログイン方法のご案内
            </div>
            <div className="login-text" onClick={() => Router.push("/signup")}>
              <span>新規登録の方はこちら</span>
              <img
                className="img"
                src="/assets/icons/arrow-right.svg"
                alt="logout"
              />
            </div>
          </div>
          <ButtonContainer>
            <div
              className="btn-how-to-use"
              onClick={() => Router.push("/how-to-use")}
            >
              <div className="img_container">
                <img src="/assets/icons/cam.svg" alt="btnImg" />
              </div>
              <span className="text">{t("How to use")}</span>
            </div>
            <div className="btn-experience" onClick={redirectToLink}>
              <div className="img_container">
                <img src="/assets/icons/messagecircle.svg" alt="btnImg" />
              </div>
              <span className="text">{t("Experience")}</span>
            </div>
          </ButtonContainer>
        </div>
      </CallToActionBtnWrapper>
      {openModal && (
        <GuideModal
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          centered
        />
      )}
    </>
  )
}
