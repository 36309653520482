import { Switch } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const VisibilityIconContainer = styled.div`
  margin-left: 5px;
  .ant-switch-checked {
    background-color: #e26e30;
    .ant-switch-handle {
      left: calc(100% - 12px - 2px);
    }
  }
  .ant-switch,
  .ant-switch-checked {
    height: 18px !important;
    min-width: 28px !important;
    max-width: 28px !important;
    .ant-switch-handle {
      width: 13px !important;
      height: 13px !important;
    }
  }
  .status {
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 400;
    text-transform: capitalize;
    white-space: nowrap;
  }
`
export const SwitchIcon: React.FC<{
  onLockClick?: () => void
  status: "public" | "private"
  showStatus?: boolean
  style?: React.CSSProperties
}> = ({ onLockClick, status, showStatus = true, style = {} }) => {
  const { t } = useTranslation()
  return (
    <VisibilityIconContainer style={{ ...style }}>
      <Switch onChange={onLockClick} checked={status === "public"} />
      {showStatus && <span className="status"> {t(status)}</span>}
    </VisibilityIconContainer>
  )
}
