import { theme } from "@project/shared"
import React from "react"
import styled from "styled-components"
import { SwitchIcon } from "../../atoms"

interface PageHeaderIconPropsTypes {
  headerTitle?: string
  icon?: React.ReactNode
  noHideOnMobile?: boolean
  alignment?: "flex-start" | "flex-end" | "center"
  className?: string
  showLockIcon?: boolean
  LockIcon?: React.ReactNode
  onClick?: () => void
  status?: "public" | "private"
}

const Header = styled.div<PageHeaderIconPropsTypes>`
  display: flex;
  justify-content: ${(props) => props.alignment || "center"};
  align-items: center;

  @media (max-width: 880px) {
    width: 95%;
  }

  @media (max-width: 786px) {
    display: ${(props) => (props.noHideOnMobile ? "flex" : "none")};
    justify-content: flex-start;
    width: 90%;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  background-color: ${theme.table.headerBackground};
  min-width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media (max-width: 786px) {
    min-width: 50px;
    height: 50px;
  }
  @media (max-width: 410px) {
    display: none;
  }
`
const Title = styled.div`
  font-size: ${theme.text.size.sm};
  color: ${theme.text.color.light};
  margin-left: 14px;
  margin-right: 35px;
  line-height: 25px;
  @media (max-width: 1025px) {
    white-space: pre-wrap;
  }
  @media (max-width: 785px) {
    margin-right: 0px;
  }
  @media (max-width: 550px) {
    font-size: 18px;
    margin-left: 10px;
  }
  @media (max-width: 410px) {
    margin-left: 0px;
  }
`
const SuffixIconWrapper = styled.div`
  margin-left: 35px;
  position: relative;
  top: 3px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`

const PageHeaderIcon: React.FC<PageHeaderIconPropsTypes> = (props) => {
  const {
    headerTitle,
    icon,
    onClick,
    showLockIcon = false,
    LockIcon,
    noHideOnMobile,
    alignment,
    className,
    status,
  } = props
  return (
    <Header
      noHideOnMobile={noHideOnMobile}
      alignment={alignment}
      className={className}
    >
      <ImgWrapper>{icon}</ImgWrapper>
      <Title>{headerTitle}</Title>
      {showLockIcon && status ? (
        <SwitchIcon
          status={status}
          onLockClick={onClick}
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        />
      ) : (
        <>
          {showLockIcon && (
            <SuffixIconWrapper onClick={onClick}>{LockIcon}</SuffixIconWrapper>
          )}
        </>
      )}
    </Header>
  )
}

export default PageHeaderIcon
