import { Button, theme } from "@project/shared"
import { Grid, Modal, notification } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { LOCKITEMS_TYPES } from "../../../constants/lockItems"
import { mutateLockedData, openLockedModal } from "../../../context/Action"
import { useGlobalContext } from "../../../context/GlobalContextProvider"
import { updateLockedContent } from "../../../services/accessibility"
import { checkAccessibilityCondition } from "../../../utils/helpers/accessibility/checkLockedItem"

interface ILockModal {
  refetch?: () => void
}
export const ModalWrapper = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border: 1px solid #808080 !important;
    border-radius: 10px !important;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
`
export const ModalBodyWrapper = styled.div`
  padding: 50px 33px 25px 33px;
  text-align: center;
  @media (max-width: 576px) {
    padding: 21px;
  }
`
export const ModalTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(props) => props.theme.font.BUS};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.s};
  .icon {
    margin-right: 11px;
    position: relative;
    top: 2px;
  }
`
export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: 27px;
  }
`

export const LockModal: React.FC<ILockModal> = (props) => {
  const { refetch } = props

  const { t } = useTranslation()

  const { state, dispatch } = useGlobalContext()

  const lockedString = state?.itemsClicked

  /**
   * Mutation hook for updating items lock status.
   */

  const { mutate, isLoading } = useMutation(updateLockedContent)

  const [updateData, setData] = useState<any>({})

  const lockedItemsArray: Array<boolean> = checkAccessibilityCondition(
    state?.userAccessibilityData
  )

  /**
   * @lockedState which looks weather the item is locked or unlocked.
   */

  const [isLocked, setIsLocked] = useState<boolean>(false)
  /**
   *
   * Preparing data for the api according to the conditions.
   */
  useEffect(() => {
    if (lockedString === LOCKITEMS_TYPES.friends_properties) {
      if (lockedItemsArray[0]) {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.friends_properties,
            friends: 1,
            assets: 1,
            insurance: 1,
            storage: 1,
          }
        })
      } else {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.friends_properties,
            friends: 0,
            assets: 0,
            insurance: 0,
            storage: 0,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.my_thoughts) {
      if (lockedItemsArray[1]) {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.my_thoughts,
            pets: 1,
            hospitals: 1,
            funeral: 1,
            grave: 1,
          }
        })
      } else {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.my_thoughts,
            pets: 0,
            hospitals: 0,
            funeral: 0,
            grave: 0,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.medias) {
      if (lockedItemsArray[2]) {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.medias,
            medias: 1,
          }
        })
      } else {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.medias,
            medias: 0,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.consultations) {
      if (lockedItemsArray[3]) {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.consultations,
            consultations: 1,
          }
        })
      } else {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.consultations,
            consultations: 0,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.friends) {
      if (state?.userAccessibilityData?.friends) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.friends,
            friends: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.friends,
            friends: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.assets) {
      if (state?.userAccessibilityData?.assets) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.assets,
            assets: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.assets,
            assets: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.insurance) {
      if (state?.userAccessibilityData?.insurance) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.insurance,
            insurance: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.insurance,
            insurance: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.storage) {
      if (state?.userAccessibilityData?.storage) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.storage,
            storage: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.storage,
            storage: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.hospitals) {
      if (state?.userAccessibilityData?.hospitals) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.hospitals,
            hospitals: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.hospitals,
            hospitals: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.pets) {
      if (state?.userAccessibilityData?.pets) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.pets,
            pets: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.pets,
            pets: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.funeral) {
      if (state?.userAccessibilityData?.funeral) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.funeral,
            funeral: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.funeral,
            funeral: 1,
          }
        })
      }
    } else if (lockedString === LOCKITEMS_TYPES.grave) {
      if (state?.userAccessibilityData?.grave) {
        setIsLocked(false)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.grave,
            grave: 0,
          }
        })
      } else {
        setIsLocked(true)
        setData(() => {
          return {
            type: LOCKITEMS_TYPES.grave,
            grave: 1,
          }
        })
      }
    }
  }, [state])

  const handleButton = () => {
    mutate(
      { ...updateData },
      {
        onSuccess: () => {
          notification.success({
            message: t("Privacy status updated successfully"),
          })
          dispatch(mutateLockedData(updateData))
          refetch()
        },
        onError: (error: any) => {
          const msg = error?.data?.error?.message
          notification.error({
            message: msg
              ? t(msg)
              : t("An error has occurred. Please try again later."),
          })
        },
        onSettled: () => {
          dispatch(openLockedModal())
        },
      }
    )
  }

  /**
   * break point form the antd
   */

  const { useBreakpoint } = Grid
  const breakPoint = useBreakpoint()
  return (
    <ModalWrapper
      closable={false}
      visible={state?.lockedModalState}
      width={breakPoint.xs ? "350px" : "729px"}
      centered
      footer={null}
    >
      <ModalBodyWrapper>
        <ModalTextWrapper>
          {t(
            "You can make the information public or private to your viewers by clicking the button."
          )}
        </ModalTextWrapper>
        <ModalButtonWrapper>
          <Button
            width={breakPoint.xs ? "240px" : "160px"}
            height="50px"
            onClick={() => dispatch(openLockedModal())}
            background={theme.button.secondary}
          >
            {t("Cancel")}
          </Button>
          <Button
            width={breakPoint.xs ? "240px" : "160px"}
            height="50px"
            margin={breakPoint.xs ? "0px 0 10px 0" : "0 0 0 22px"}
            background={theme.button.primary}
            loading={isLoading}
            onClick={handleButton}
          >
            {isLocked ? t("Public") : t("Lock")}
          </Button>
        </ModalButtonWrapper>
      </ModalBodyWrapper>
    </ModalWrapper>
  )
}

export default LockModal
