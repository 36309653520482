import styled from "styled-components"
import HomepageFooter from "../HomepageFooter"
import { CallToActionBtn } from "./CallToActionBtn"
import Header from "./header"
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
const Landing = () => {
  return (
    <Wrapper>
      <Header />
      {/* <Slider /> */}
      {/* <Signup /> */}
      <CallToActionBtn />
      <HomepageFooter />
    </Wrapper>
  )
}

export { Landing }
