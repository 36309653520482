// import { Menu, notification } from "antd"
import { theme } from "@project/shared"
import type { MenuProps } from "antd"
import { Menu } from "antd"
import { t } from "i18next"
import Router from "next/router"
import { useEffect, useState } from "react"
import styled from "styled-components"
import {
  consultantMemoMenu,
  dataFirstNav,
  dataFourthNav,
  dataSecondNav,
  dataThirdNav,
} from "../PageHeader/MenuItems"
// import { authContext } from "../../../utils"
import { useGlobalContext } from "../../../context/GlobalContextProvider"

const CustomMenu = styled(Menu)`
  font-size: 18px;
  font-weight: 500;
  .play-video-icon {
    svg {
      font-size: 30px;
    }
  }
  .ant-menu-light .ant-menu-item-activ {
    color: #000000;
  }
  li {
    .anticon {
      margin-top: 0px !important;
    }
    .ant-menu-title-content {
      margin-left: 0px !important;
    }
  }
`
interface Props {
  handleLogOut?: () => void
  pendingApprovalCount?: any
  handleLockIconClick?: (items: string) => void
  activeKey: string
  setActiveKey: any
  isOpenDrawer?: boolean
  lockedItemsArray?: Array<boolean>
  keyList?: string[]
}

const TagWrapper = styled.span`
  border-radius: 100px;
  margin-left: 10px;
  color: white;
  border: none;
  font-size: 16px;
  display: inline-grid;
  justify-content: center;
  width: 60px;
  height: 30px;
  align-content: center;
  font-family: ${theme.font.BUS} !important;
  background: ${theme.button.primary};
`

// const Description = styled.div`
//   .shareImg {
//     filter: invert(8%) sepia(100%) saturate(5481%) hue-rotate(216deg)
//       brightness(102%) contrast(94%);
//     width: 20px;
//     height: 20px;
//     font-weight: bold;
//   }
// `

const CommonMobileMenu = ({
  handleLogOut,
  pendingApprovalCount,
  handleLockIconClick,
  activeKey,
  setActiveKey,
  isOpenDrawer,
  keyList,
  lockedItemsArray = [],
}: Props) => {
  const rootSubmenuKeys: Array<string> = ["2", "3", "4", "5"]
  const [openedKeys, setOpenedKeys] = useState<Array<string>>([])

  const currentPath: string = Router.asPath?.split("/")?.[1]

  // const { deferredPrompt, setDeferredPrompt } = authContext()

  const { state } = useGlobalContext()

  // const isIos = () => {
  //   const userAgent = window.navigator.userAgent.toLowerCase()
  //   return /iphone|ipad|ipod/.test(userAgent)
  // }
  // const isInStandaloneMode = () =>
  //   "standalone" in window.navigator && window.navigator["standalone"]

  // const openNotification = () => {
  //   notification.open({
  //     placement: "bottomRight",
  //     bottom: 0,
  //     description: (
  //       <Description>
  //         {t("Click your browser")}{" "}
  //         <img className="shareImg" src="/assets/icons/share.svg" alt="share" />
  //         {t("and tap Add to home screen to install this app.")}
  //       </Description>
  //     ),
  //     message: "",
  //     closeIcon: <div></div>,
  //     duration: 5,
  //     style: {
  //       width: "100%",
  //     },
  //   })
  // }

  // const promptAppInstall = async () => {
  //   if (isIos() && !isInStandaloneMode()) {
  //     openNotification()
  //   }
  //   if (!isIos()) {
  //     if (deferredPrompt) {
  //       deferredPrompt.prompt()
  //       await deferredPrompt.userChoice
  //       setDeferredPrompt(null)
  //     } else {
  //       // Do something when app is already installed
  //       // eslint-disable-next-line no-alert
  //       alert("You have already installed app!")
  //     }
  //   }
  // }

  useEffect(() => {
    switch (currentPath) {
      case "friends-property":
        setActiveKey("20")
        break
      case "my-thoughts":
        setActiveKey("21")
        break
      case "pictures-memories":
        setActiveKey("22")
        break
      case "family":
        setActiveKey("30")
        break
      case "invite-family":
        setActiveKey("31")
        break
      case "approve-family":
        setActiveKey("32")
        break
      case "careful-notes":
        setActiveKey("33")
        break
      case "inquiry":
        setActiveKey("40")
        break
      case "delete-account":
        setActiveKey("42")
        break
      case "consultation-memo":
        if (Router?.asPath == "/consultation-memo/admin") {
          setActiveKey("38")
        } else if (Router?.asPath == "/consultation-memo/user") {
          setActiveKey("39")
        }
        break
      case "update-information":
        setActiveKey("50")
    }
  }, [currentPath, isOpenDrawer])

  /**
   * @dependencies are @currentPath which looks while loading,
   * @activeKey which rerenders after the first useEffect(above)
   * and @isOpenDrawer which resets active key to the one while at first page load.
   */
  useEffect(() => {
    const splitedKey = activeKey?.split("")?.[0]
    Boolean(parseInt(splitedKey)) && setOpenedKeys([splitedKey])
  }, [currentPath, activeKey, isOpenDrawer])
  const isFront = keyList
  const MobileMenuItems: MenuProps["items"] = [
    {
      key: "1",
      icon: (
        <img
          src="/assets/icons/home.svg"
          height="27px"
          width="22px"
          style={{ marginRight: "10px" }}
        />
      ),
      label: t("Home"),
      onClick: () => Router.push("/top"),
    },
    {
      key: "2",
      icon: isFront ? (
        <img
          src="/assets/icons/mob-note-edit.svg"
          height="43px"
          width="43px"
          style={{ marginRight: "10px" }}
        />
      ) : (
        <img
          src="/assets/icons/note-edit.svg"
          height="27px"
          width="22px"
          style={{ marginRight: "10px" }}
        />
      ),
      label: t("Write notes"),
      children: dataFirstNav(
        t,
        handleLockIconClick,
        lockedItemsArray[0],
        lockedItemsArray[1],
        lockedItemsArray[2],
        state?.userAccessibilityData?.isLoading,
        "column"
      ),
    },
    {
      key: "6",
      icon: isFront ? (
        <img
          src="/assets/icons/consult-note-mobile.svg"
          height="43px"
          width="43px"
          style={{ marginRight: "10px" }}
        />
      ) : (
        <img
          src="/assets/icons/memo-note.svg"
          height="27px"
          width="22px"
          style={{ marginRight: "10px" }}
        />
      ),
      label: t("Write consult memo"),
      children: consultantMemoMenu(
        t,
        handleLockIconClick,
        lockedItemsArray[3],
        state?.userAccessibilityData?.isLoading
      ),
    },
    {
      key: "3",
      icon: isFront ? (
        <img
          src="/assets/icons/mob-user.svg"
          height="43px"
          width="43px"
          style={{ marginRight: "10px" }}
        />
      ) : (
        <img
          src="/assets/icons/user.svg"
          height="27px"
          width="22px"
          style={{ marginRight: "10px" }}
        />
      ),

      label: (
        <>
          {t("Family share")}
          <TagWrapper>
            <span>{pendingApprovalCount?.count}件</span>
          </TagWrapper>
        </>
      ),
      children: dataSecondNav(t, pendingApprovalCount?.count),
    },
    {
      key: "4",
      icon: (
        <img
          src="/assets/icons//mail_user.svg"
          height="27px"
          width="22px"
          style={{ marginRight: "10px" }}
        />
      ),
      label: t("Inquiry"),
      children: dataThirdNav(t),
    },
    {
      key: "5",
      icon: (
        <img
          src="/assets/icons/settings.svg"
          height="27px"
          width="22px"
          style={{ marginRight: "10px" }}
        />
      ),
      label: t("Settings"),
      children: dataFourthNav(t, handleLogOut),
    },
    // {
    //   key: "6",
    //   icon: (
    //     <img src="/assets/icons/homescreen2.svg" height="27px" width="22px" />
    //   ),
    //   label: t("Add to home screen"),
    //   onClick: () => {
    //     promptAppInstall()
    //   },
    // },
  ]

  /**
   *
   * @param keys
   * active menu items handlers.
   */
  const onOpenMenuItems: MenuProps["onOpenChange"] = (keys: []) => {
    const latestOpenKey = keys.find((key) => openedKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenedKeys(keys)
    } else {
      setOpenedKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const filterMenuItems = () => {
    const filteredMenuItems = []
    keyList.forEach((key) => {
      MobileMenuItems.forEach((item) => {
        if (item.key == key) filteredMenuItems.push(item)
      })
    })
    return filteredMenuItems
  }

  return (
    <div>
      <CustomMenu
        items={keyList?.length > 0 ? filterMenuItems() : MobileMenuItems}
        mode="inline"
        openKeys={openedKeys}
        selectedKeys={[activeKey]}
        onOpenChange={onOpenMenuItems}
      />
    </div>
  )
}

export default CommonMobileMenu
