import { t } from "i18next"
import { useRouter } from "next/router"
import { useState } from "react"
import { useQuery } from "react-query"
import { lockContent, openLockedModal } from "../../../context/Action"
import { useGlobalContext } from "../../../context/GlobalContextProvider"
import { getPendingApprovalCount } from "../../../services/family"
import { checkAccessibilityCondition } from "../../../utils/helpers/accessibility/checkLockedItem"
import CommonMobileMenu from "../CommonMobileMenu"
import { Container, Img, Title } from "./styles"

const MobileMenu = () => {
  const { state, dispatch } = useGlobalContext()
  const router = useRouter()
  const [activeKey, setActiveKey] = useState<string>("01")
  const handleLockIconClick = (item: string) => {
    dispatch(openLockedModal())
    dispatch(lockContent(item))
  }
  const lockedItemsArray: Array<boolean> = checkAccessibilityCondition(
    state?.userAccessibilityData
  )

  const { data: pendingApprovalCount } = useQuery(
    "pending-approval-count",
    getPendingApprovalCount
  )

  return (
    <Container type={router.pathname === "/top" ? "top" : ""}>
      {router.pathname === "/top" ? (
        <Img>
          <img width={"240px"} src="/assets/imgs/footer-logo.svg" alt="Logo" />
        </Img>
      ) : (
        <Title>{t("Menu")}</Title>
      )}
      <CommonMobileMenu
        handleLockIconClick={handleLockIconClick}
        lockedItemsArray={lockedItemsArray}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        pendingApprovalCount={pendingApprovalCount}
        keyList={router.pathname === "/top" && ["2", "6", "3"]}
      />
    </Container>
  )
}

export { MobileMenu }
